import React from 'react';
import PropTypes from "prop-types";
import { Link } from "gatsby";

const Footer = ({ container = "container mx-auto px-2 md:px-4" }) =>  (
  <footer className="bg-primary">
    <nav className={`${container} flex flex-wrap justify-between text-sm py-16 md:py-24`}>
      <div className="w-full md:w-1/4 mb-5 md:mb-0">
        <h2 className="text-white text-2xl font-bold leading-tight mb-2">Maibo <br/> Demolition & Groundworks</h2>
        <p className="text-white text-sm font-medium opacity-50">Our wide range of plant equipment is regularly maintained by skilled technicians, we believe that a safe working environment is key to achieving high standards.</p>
      </div>
      <div className="w-full md:w-auto mb-5 md:mb-0">
        <h3 className="text-white text-lg font-bold leading-tight mb-2 md:mb-5">Navigation</h3>
        <ul className="text-white text-sm font-medium leading-loose">
          <li><Link className="opacity-50" activeClassName="font-bold opacity-75" to="/">Home</Link></li>
          <li><Link className="opacity-50" activeClassName="font-bold opacity-75" to="/our-services">Services</Link></li>
          <li><Link className="opacity-50" activeClassName="font-bold opacity-75" to="/portfolio">Portfolio</Link></li>
          <li><Link className="opacity-50" activeClassName="font-bold opacity-75" to="/contact">Contact</Link></li>
        </ul>
      </div>
      <div className="w-full md:w-2/5">
        <h3 className="text-white text-lg font-bold leading-tight mb-2 md:mb-5">CPCS Certified</h3>
      </div>
    </nav>
    <div className="w-full bg-primaryDark">
      <div className={`${container} text-sm text-white opacity-50 py-6 flex flex-wrap justify-between`} >
        Copyright ©2020 All rights reserved 
        <Link className="opacity-50" activeClassName="font-bold opacity-75" to="/terms-and-conditions">Terms & Conditions</Link>
        </div>
    </div>
  </footer>
);


Footer.propTypes = {
  container: PropTypes.string,
  text: PropTypes.element,
};

export default Footer;